<template>
  <div class="bookingBlock__content">
    <vue-scroll :ops="ops">
      <div class="bookingBlock__content-inner">
        <FlyGreenCalc/>
        <router-link class="bookingBlock__content-prev js-bookingNav-item" to="terms">
          <span class="icon">
            <SvgIcon name="left-arrow"/>
          </span>
          <span class="text">Back</span>
        </router-link>
        <router-link class="bookingBlock__content-next js-bookingNav-item" to="payment">
          <span class="icon">
            <SvgIcon name="right-arrow"/>
          </span>
          <span class="text">Next</span>
        </router-link>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
  import './FlyGreen.styl'
  import FlyGreenCalc from '@/components/dashboard/FlyGreenCalc/FlyGreenCalc'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'

  export default {
    name: 'flyGreen',
    data: () => ({
      switchForm: true,
      offset: 'tripEmissions',
      carbonEmissionsGenerated: 404.7,
      purchaseValue: 10,
      carbonEmissions: 404.7,
      percentEmissions: 100,
      loading: false,
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    components: {
      FlyGreenCalc,
      SvgIcon,
    },
  }
</script>
